import { StaticImage } from "gatsby-plugin-image";
import * as React from "react"
import { Helmet } from "react-helmet";
import Layout from "../layout/default"

import * as styles from "./index.module.scss";

import zombie_gif_1 from "../assets/zombies/pinata_scaled.gif";
import zombie_gif_2 from "../assets/zombies/tank_scaled.gif";
import zombie_gif_3 from "../assets/zombies/runner_scaled.gif";

import sharks_gif_1 from "../assets/sharks/shark_bigdaddy.gif";
import sharks_gif_2 from "../assets/sharks/shark_horn.gif";
import sharks_gif_3 from "../assets/sharks/shark_old.gif";

import skeleton_gif_1 from "../assets/skeletons/skeleton_tank_scaled.gif";
import skeleton_gif_2 from "../assets/skeletons/skeletongeneral_scaled.gif";
import skeleton_gif_3 from "../assets/skeletons/skeletonmage_scaled.gif";

import desert_gif_1 from "../assets/desert/MINION_MAGE_scaled.gif";
import desert_gif_2 from "../assets/desert/MUMMY_scaled.gif";
import desert_gif_3 from "../assets/desert/SCORPION_scaled.gif";

const IndexPage = () => {
  return (
    <Layout>
      <Helmet title="Chunk Studios">
      {[
                {
                    name: `description`,
                    content: "Chunk Studios is a team of artists, animators, developers, writers, musicians, and IT specialists. Our goal is to create deep, enjoyable, and immersive products.",
                },
                {
                    property: `og:title`,
                    content: "Chunk Studios",
                },
                {
                    property: `og:description`,
                    content: "Chunk Studios is a team of artists, animators, developers, writers, musicians, and IT specialists. Our goal is to create deep, enjoyable, and immersive products.",
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                /*{
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },*/
                {
                    name: `twitter:title`,
                    content: "Chunk Studios",
                },
                {
                    name: `twitter:description`,
                    content: "Chunk Studios is a team of artists, animators, developers, writers, musicians, and IT specialists. Our goal is to create deep, enjoyable, and immersive products.",
                },
                {
                    name: "keywords",
                    content: "chunk studios, chunk, studios, art, artists, portfolio, animation, animator, development, developer, software, games, writer, writing, writers, music, musicians, it, information technologies, immersive, deep, enojable, enjoyment",
                },
            ]
                .map((m) => {
                    return (
                        <meta
                            key={m.name}
                            name={m.name}
                            content={m.content}
                        ></meta>
                    );
                })}
        

      </Helmet>

      <aside className={styles.sidenav} id="sidenav">
        <ul>
          <li><a href="#top">Who are we?</a></li>
          <li>
            <a href="#projects">Projects</a>
            <ul>
              <li><a href="#project-zombies-apocalypse">Zombies Apocalypse</a></li>
              <li><a href="#project-sharks">Sharks</a></li>
            </ul>
          </li>
          <li><a href="#models">Models</a></li>
          <li><a href="#level-design">Level Design</a></li>
        </ul>
      </aside>
      <main id="content" className={styles.content}>
        <h2 id="top">Who are we?</h2>
        <p>Chunk Studios is a team of artists, animators, developers, writers, musicians, and IT specialists. Our goal is to create deep, enjoyable, and immersive products.</p>
        <p>We have a combined 13 Years of experience creating content for the Minecraft Marketplace. Our members have worked with Marketplace Partners like Spark Universe, Shapescape, SNDBX, HorizonBlocks, Vaeron, and Mine-North.</p>

        <h2 id="projects">Projects</h2>
        <h3 id="project-zombies-apocalypse">Zombies Apocalypse</h3>

        <div className={styles.description}>
          <div><p>A Minigame Adventure, where the player has to defend himself against Zombies in three different Maps. Includes 3 Bosses, Custom Weapons &amp; different difficulties.</p></div>
          <iframe src="https://www.youtube-nocookie.com/embed/ES5xeh27qWc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div className={styles.imageRow}>
          <StaticImage src="../assets/zombies/zombieapocalypse_MarketingScreenshot_0.jpg" alt="Zombies screenshot showing players on the street fighting a horde of zombies" />
          <StaticImage src="../assets/zombies/zombieapocalypse_MarketingScreenshot_1.jpg" alt="Zombies screenshot showing players underground fighting a horde of zombies" />
        </div>
        <div className={styles.imageRow}>
          <div><img src={zombie_gif_1} alt="Pinata Zombie" /></div>
          <div><img src={zombie_gif_2} alt="Tank Zombie" /></div>
          <div><img src={zombie_gif_3} alt="Runner Zombie" /></div>
        </div>



        <h3 id="project-sharks">Sharks</h3>

        <div className={styles.description}>
          <div><p>A Survival Spawn created for Mine-North. It features several sharks and three vehicles.</p></div>
        </div>

        <div className={styles.imageRow}>
          <StaticImage src="../assets/sharks/Sharks_MarketingKeyArt.jpg" alt="Keyart featuring a bunch of sharks and the text 'Shark'" />
        </div>

        <div className={styles.imageRow}>
          <div><img src={sharks_gif_1} alt="Big Daddy Shark" /></div>
          <div><img src={sharks_gif_2} alt="Horn Shark" /></div>
          <div><img src={sharks_gif_3} alt="Old Shark" /></div>
        </div>

        <h2 id="models">Models</h2>

        <h3>Ice Skeletons for an upcoming project</h3>

        <div className={styles.imageRow}>
          <div><img src={skeleton_gif_1} alt="Tank Skeleton" /></div>
          <div><img src={skeleton_gif_2} alt="General Skeleton" /></div>
          <div><img src={skeleton_gif_3} alt="Mage Skeleton" /></div>
        </div>

        <h3>Mobs for an upcoming desert project</h3>

        <div className={styles.imageRow}>
          <div><img src={desert_gif_1} alt="Mage Minion" /></div>
          <div><img src={desert_gif_2} alt="Mummy" /></div>
          <div><img src={desert_gif_3} alt="Scorpion" /></div>
        </div>

        <h2 id="level-design">Level Design</h2>

        <h3>The Bridge: A Fantasy Level Design Test</h3>

        <div className={styles.imageRow}>
          <StaticImage src="../assets/building/bridge.png" alt="Render of a medieval-style bridge surrounded by fog" />
        </div>


        <h3>Pirate Bay: A scifi pirate map</h3>

        <div className={styles.imageRow}>
          <StaticImage src="../assets/building/night2.png" alt="Render of a beach at night with futuristic buildings on it and starships hovering above it" />
        </div>


        <h3>The Shipwreck: An artistic recreation of the Titanic</h3>

        <div className={styles.imageRow}>
          <StaticImage src="../assets/building/wioUBYO.jpg" alt="Render of a shipwreck broken in half at the bottom of the ocean surrounded by rocks and glowing jellyfish" />
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage
